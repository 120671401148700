import type { PrefectureWithSection } from '~/typeEntities/stores/Prefecture'
type SelectedOptions = {
  [key: number]: {
    selectAll: boolean
    sectionIds: number[]
  }
}
export const usePrefecturesModalStore = defineStore('prefectures-modal-store', {
  state: () => ({
    currentPrefectureTabId: Number(13),
    selectedOptions: {} as SelectedOptions,
  }),
  actions: {
    setSelectedOptions(options: SelectedOptions) {
      this.selectedOptions = options
    },
    setSelectedOptionsByFlatParams(
      prefectureIds: number[],
      sectionIds: number[]
    ) {
      const retOption = {} as SelectedOptions
      this.prefectures
        .filter((p) => {
          return (
            prefectureIds.includes(p.id) ||
            p.sections.some((s) => sectionIds.includes(s.id))
          )
        })
        .forEach((p) => {
          const nestedSectionIds = p.sections
            .filter((s) => sectionIds.includes(s.id))
            .map((s) => s.id)
          const selectAll = nestedSectionIds.length <= 0
          const ids = selectAll
            ? p.sections.map((s) => s.id)
            : p.sections
                .filter((s) => nestedSectionIds.includes(s.id))
                .map((s) => s.id)
          retOption[p.id] = {
            selectAll,
            sectionIds: ids,
          }
        })
      this.selectedOptions = retOption
    },
    setCurrentPrefectureTabId(id: number) {
      this.currentPrefectureTabId = id
    },
    addSelectedSection(sectionId: number) {
      const pref = this.getPrefectureBySectionId(sectionId)
      if (!pref) {
        return
      }
      const prefId = Number(pref.id)
      if (!this.selectedOptions[prefId]) {
        this.selectedOptions[prefId] = {
          selectAll: false,
          sectionIds: [sectionId],
        }
      } else {
        const sectionIds = this.selectedOptions[prefId].sectionIds
        this.selectedOptions[prefId].sectionIds.push(sectionId)
        if (sectionIds.length === pref.sections.length) {
          this.selectedOptions[prefId].selectAll = true
        }
      }
    },
    removeSelectedSection(sectionId: number) {
      const pref = this.getPrefectureBySectionId(sectionId)
      if (!pref) {
        return
      }
      const prefId = Number(pref.id)
      if (!this.selectedOptions[prefId]) {
        return
      }
      const sectionIds = this.selectedOptions[prefId].sectionIds
      this.selectedOptions[prefId].sectionIds = sectionIds.filter(
        (id) => id !== sectionId
      )
      if (this.selectedOptions[prefId].selectAll) {
        this.selectedOptions[prefId].selectAll = false
      }
    },
    addSelectedPrefecture(prefectureId: number) {
      const prefecture = this.prefectures.find((p) => p.id === prefectureId)
      if (!prefecture) {
        return
      }
      const ids = prefecture.sections.map((section) => section.id)
      this.selectedOptions[prefectureId] = {
        selectAll: true,
        sectionIds: ids,
      }
    },
    removeSelectedPrefecture(prefectureId: number) {
      delete this.selectedOptions[prefectureId]
    },
    removeSelectedPrefectureBySectionId(sectionId: number) {
      const pref = this.getPrefectureBySectionId(sectionId)
      if (!pref) {
        return
      }
      this.removeSelectedPrefecture(pref.id)
    },
    toggleSelectAll() {
      const prefId = Number(this.getCurrentPrefecture?.id)
      const ids =
        this.getCurrentPrefecture?.sections?.map((section) => section.id) || []
      if (!this.selectedOptions[prefId]) {
        this.selectedOptions[prefId] = {
          selectAll: true,
          sectionIds: ids,
        }
      } else {
        this.selectedOptions[prefId].selectAll =
          !this.selectedOptions[prefId].selectAll
        this.selectedOptions[prefId].sectionIds = this.selectedOptions[prefId]
          .selectAll
          ? ids
          : []
      }
    },
  },
  getters: {
    prefectures: () => {
      const prefectureStore = usePrefecturesStore()
      return prefectureStore.prefectures
    },
    isSelectedById: (state) => {
      return (id: number) => {
        return id === state.currentPrefectureTabId
      }
    },
    getCurrentPrefecture(state): PrefectureWithSection | undefined {
      return this.prefectures.find(
        (pref) => Number(pref.id) === state.currentPrefectureTabId
      )
    },
    getPrefectureOption: (state) => {
      return (id: number) => {
        return (
          state.selectedOptions[id] || {
            selectAll: false,
            sectionIds: [],
          }
        )
      }
    },
    getPrefecture() {
      return (id: number) => {
        return this.prefectures.find((pref) => Number(pref.id) === id)
      }
    },
    getHasActiveSection() {
      return (id: number) => {
        const option = this.getPrefectureOption(id)
        return option.selectAll || option.sectionIds.length > 0
      }
    },
    getCurrentPrefectureOption: (state) => {
      return (
        state.selectedOptions[state.currentPrefectureTabId] || {
          selectAll: false,
          sectionIds: [],
        }
      )
    },
    getIsSectionSelectedInCurrentPrefecture() {
      return (id: number) => {
        return this.getCurrentPrefectureOption.sectionIds.includes(id)
      }
    },
    getSelectAllOfPrefecture() {
      return (id: number) => {
        return this.getPrefectureOption(id).selectAll || false
      }
    },
    getSelectAllOfCurrentPrefecture(): boolean {
      return this.getCurrentPrefectureOption.selectAll
    },
    getParamsObject: (state) => {
      const returnOption = {
        prefectureIds: [] as number[],
        sectionIds: [] as number[],
      }
      Object.keys(state.selectedOptions).forEach((key) => {
        const id = parseInt(key)
        if (!state.selectedOptions[id]) {
          return
        }
        if (state.selectedOptions[id].selectAll) {
          returnOption.prefectureIds = returnOption.prefectureIds.concat([id])
          return
        }
        returnOption.sectionIds = returnOption.sectionIds.concat(
          state.selectedOptions[id].sectionIds
        )
      })
      return returnOption
    },
    getSelectedIdsInPrefecture() {
      return (id: number) => {
        return this.getPrefectureOption(id).sectionIds
      }
    },
    getPrefectureBySectionId() {
      return (sectionId: number) => {
        return this.prefectures.find((pref) =>
          pref.sections.some((section) => section.id === sectionId)
        )
      }
    },
    getSelectedSnippet(state) {
      return () => {
        let retArray = [] as string[]
        Object.keys(state.selectedOptions).forEach((key) => {
          const id = parseInt(key)
          if (!state.selectedOptions[id]) {
            return
          }
          if (state.selectedOptions[id].selectAll) {
            retArray.push(this.getPrefecture(id)?.name.valueOf() || '')

            return
          }
          const sectionNameArray = state.selectedOptions[id].sectionIds.map(
            (sectionId) => {
              return (
                this.getPrefecture(id)
                  ?.sections.find((section) => Number(section.id) === sectionId)
                  ?.name.valueOf() || ''
              )
            }
          )
          retArray = retArray.concat(sectionNameArray)
        })
        if (retArray.length > 0) {
          return retArray.join(',')
        } else {
          return null
        }
      }
    },
  },
})
