<script setup lang="ts">
type Props = {
  color?: string
}
const props = withDefaults(defineProps<Props>(), {
  color: '#D9D0D3',
})
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 7.5C3.5 5.29086 5.29086 3.5 7.5 3.5H16.5C18.7091 3.5 20.5 5.29086 20.5 7.5V16.5C20.5 18.7091 18.7091 20.5 16.5 20.5H7.5C5.29086 20.5 3.5 18.7091 3.5 16.5V7.5Z"
      :fill="props.color"
    />
    <path
      d="M16.7709 9.27066L16.771 9.27064C16.9256 9.11593 17.0125 8.90613 17.0125 8.68737C17.0125 8.46861 16.9256 8.2588 16.771 8.10409L16.7709 8.10405C16.6162 7.94939 16.4064 7.8625 16.1876 7.8625C15.9689 7.8625 15.7591 7.94939 15.6044 8.10405L15.6043 8.10407L10.2501 13.4583L8.02093 11.2291L8.02095 11.229L8.01845 11.2266C7.86285 11.0764 7.65446 10.9932 7.43814 10.9951C7.22183 10.997 7.01491 11.0837 6.86195 11.2367C6.70899 11.3896 6.62222 11.5966 6.62034 11.8129C6.61846 12.0292 6.70162 12.2376 6.8519 12.3932L6.85188 12.3932L6.85434 12.3957L9.66684 15.2082L9.66686 15.2082C9.82157 15.3628 10.0314 15.4497 10.2501 15.4497C10.4689 15.4497 10.6787 15.3628 10.8334 15.2082L10.8334 15.2082L16.7709 9.27066Z"
      fill="white"
      stroke="white"
      stroke-width="0.4"
    />
  </svg>
</template>
